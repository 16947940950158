import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import styles from './styles.module.sass';
import SubLinks from '../SubLinks';

export default () => {
  const largeScreen = useMediaQuery('@media(min-width: 1280px)'); //This one should be done properly and use useMediaQuery(theme => theme.breakpoints.up('md'));
  return <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "Use-Case-Preventive-Maintenance.jpg"}}) {
          fluid(maxWidth: 1922, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <SectionWrapper  id={i18n('_hash:preventive-maintenace')}>
        <Grid container justifyContent='center' alignContent='center' alignItems='center'
          className={styles.container}>
            {largeScreen 
              ? null 
              : <Grid item xs={12} sm={12} md={12} lg={6}
                className={styles.imageWrapper}>
                <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
                  objectFit='contain'
                  className={styles.imageContainer}
                  fluid={pic.fluid}
                  alt={i18n('Preventive maintenance - shedule and automate maintenance')}/>
              </Grid>
            }
          <Grid item container xs={12} sm={12} md={12} lg={6}
            alignContent='center'
            alignItems='center'
            justifyContent='flex-start'
            className={styles.callToActionContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('Preventive maintenance')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Schedule and automate all regular maintenance tasks, no matter if it’s about own vehicles or customer vehicles.')}
            </Typography>
            <br/>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('By using the OBI+ services, fleet managers can increase efficiency and maximise vehicle update, workshops can be proactive and contact customers at the right time, while drivers should not feel helpless when the service light turns on. OBI+ adds recommended maintenance schedules directly to make daily life better and smarter.')}
            </Typography>
            <SubLinks sublinks={[{
              text: i18n('Fleet management'),
              url: i18n('_url:fleet-management')
            },{
              text: i18n('Repair shops'),
              url: i18n('_url:repair-shop')
            }, {
              text: i18n('Leasing'),
              url: i18n('_url:leasing')
            }, {
              text: i18n('Dealerships'),
              url: i18n('_url:dealership')
            }, {
              text: i18n('Get started'),
              url: i18n('_url:sign-up'),
              button: true,
            }]}/>
          </Grid>
          {largeScreen 
            ? <Grid item xs={12} sm={12} md={12} lg={6}
              className={styles.imageWrapper}>
              <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
                objectFit='contain'
                className={styles.imageContainer}
                fluid={pic.fluid}
                alt={i18n('Preventive maintenance - shedule and automate maintenance')}/>
            </Grid>
            : null
          }
        </Grid>
      </SectionWrapper>
    )}/>
};