import React from "react";
import Layout from "../../components/Layout"
import SEO from "../../components/seo";
import sitemap from '../../data/sitemap';
import Hero from './Hero';
import RemoteMonitoring from './RemoteMonitoring';
import PreventiveMaintenance from './PreventiveMaintenance';
import DigitalDocumentation from './DigitalDocumentation';
import DigitalCommunication from './DigitalCommunication';
import FleetManagement from './FleetManagement';
import GreenDriving from './GreenDriving';
import CustomerSatisfaction from './CustomerSatisfaction';
import EvOwnership from './EvOwnership';
import CallToAction from '../CallToAction';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.useCases}
    url='_url:use-cases'>
    <SEO {...seo} location={location}/>
    <Hero/>
    <RemoteMonitoring/>
    <PreventiveMaintenance/>
    <DigitalDocumentation/>
    <DigitalCommunication/>
    <FleetManagement/>
    <GreenDriving/>
    <CustomerSatisfaction/>
    <EvOwnership/>
    <CallToAction/>
  </Layout>
)