import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import styles from './styles.module.sass';
import SubLinks from '../SubLinks';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "Use-Case-Fleet-Management.png"}}) {
          fluid(maxWidth: 1922, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <SectionWrapper id={i18n('_hash:fleet-management')}>
        <Grid container justifyContent='center' alignContent='center' alignItems='center'
          className={styles.container}>
          <Grid item xs={12} sm={12} md={12} lg={6}
            className={styles.imageWrapper}>
            <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
              objectFit='contain'
              className={styles.imageContainer}
              fluid={pic.fluid}
              alt={i18n('Manage all fleet-related operations on a connected car platform')}/>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={6}
            alignContent='center'
            alignItems='center'
            justifyContent='flex-start'
            className={styles.callToActionContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('Fleet management')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('More efficient daily operations for any business operating with vehicles, no matter the size. Fleet management is often mistaken with vehicle tracking. However, tracking is only one of the aspects that could improve operations.')}
            </Typography>
            <br/>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Fleet management combines all tasks of a fleet owner/manager, from selecting the vehicles that fit the operations, operating these on a daily basis, to selling those at some point. Throughout all that time, there is a need to complete mandatory vehicle-related tasks like service and maintenance, fuel management, security, manage responsibilities of employees, tracking usage, and more. Managing the fleet operations should be easier and more convenient.')}
            </Typography>
            <SubLinks sublinks={[{
              text: i18n('Fleet management'),
              url: i18n('_url:fleet-management')
            }, {
              text: i18n('Get started'),
              url: i18n('_url:sign-up'),
              button: true,
            }]}/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}/>
);