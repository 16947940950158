import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        hero: imageSharp (fluid: {
          originalName: { eq: "Use-Case-Hero.jpg" }
        }) {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ hero }) => (
      <SectionWrapper className={styles.container} direction='row'>
        <Grid item xs={12} sm={12} md={6} className={styles.overviewContainer}>
          <Typography
            variant='h1'
            color='dark'
            weight='bold'
            align='center'
            className={clsx(
              styles.title,
              styles.heroText
            )}>
            {i18n('Empowering innovation and exploring new exciting spaces.')}
          </Typography>
        </Grid>
        <Grid item className={styles.splashContainer}
          xs={12} sm={12} md={12} lg={12}>
          <Img imgStyle={{ objectFit: 'contain' }}
            objectFit='contain'
            objectPosition='top right'
            className={styles.imageContainer}
            fluid={hero.fluid}
            alt={i18n('Use cases of how connected car solutions used')}/>
        </Grid>
      </SectionWrapper>
    )
  }/>
);