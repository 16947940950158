import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import styles from './styles.module.sass';
import SubLinks from '../SubLinks';

export default () => {
  const largeScreen = useMediaQuery('@media(min-width: 1280px)'); //This one should be done properly and use useMediaQuery(theme => theme.breakpoints.up('md'));
  return <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "Use-Case-EV-Ownership.jpg"}}) {
          fluid(maxWidth: 1922, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <SectionWrapper  id={i18n('_hash:ev-ownership')}>
        <Grid container justifyContent='center' alignContent='center' alignItems='center'
          className={styles.container}>
            {largeScreen 
              ? null 
              : <Grid item xs={12} sm={12} md={12} lg={6}
                className={styles.imageWrapper}>
                <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
                  objectFit='contain'
                  className={styles.imageContainer}
                  fluid={pic.fluid}
                  alt={i18n('EV ownership and digital services for electric vehicles')}/>
              </Grid>
            }
          <Grid item container xs={12} sm={12} md={12} lg={6}
            alignContent='center'
            alignItems='center'
            justifyContent='flex-start'
            className={styles.callToActionContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('EV ownership')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Ownership of electric vehicles is new to almost everyone. It has its characteristics and specific needs.')}
            </Typography>
            <br/>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('The number of electric vehicles will grow tremendously in the next few years and with that the interest in digital services that make EV ownership more convenient for private owners, businesses or public institutions. Both the EV owners and their service providers would require a new set of tools to help them.')}
            </Typography>
            <SubLinks sublinks={[{
              text: i18n('Fleet management'),
              url: i18n('_url:fleet-management')
            },{
              text: i18n('Repair shops'),
              url: i18n('_url:repair-shop')
            }, {
              text: i18n('Leasing'),
              url: i18n('_url:leasing')
            }, {
              text: i18n('Dealerships'),
              url: i18n('_url:dealership')
            }, {
              text: i18n('Get started'),
              url: i18n('_url:sign-up'),
              button: true,
            }]}/>
          </Grid>
          {largeScreen 
            ? <Grid item xs={12} sm={12} md={12} lg={6}
              className={styles.imageWrapper}>
              <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
                objectFit='contain'
                className={styles.imageContainer}
                fluid={pic.fluid}
                alt={i18n('EV ownership and digital services for electric vehicles')}/>
            </Grid>
            : null
          }
        </Grid>
      </SectionWrapper>
    )}/>
};