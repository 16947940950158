import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import styles from './styles.module.sass';

export default ({ sublinks }) => (
  <Grid container justifyContent='flex-start'
    alignItems='flex-start'
    className={styles.container}>
    {sublinks.map(({ text, url, button }) => (
      <Grid item className={button ? styles.buttonItem : styles.linkItem}
        key={text}
        alignItems='center'>
        { button
          ? <div className= {styles.buttonContainer}>
            <Link to={url}
              style={{ textDecoration: 'none'}}>
              <Button className={styles.button}>
                {text}
              </Button>
            </Link>
          </div>
          : <Link to={url}>
            <div className={styles.linkContainer}>
              <Typography className={styles.linkText}
                variant='h5'
                weight='light'
                color='dark'>
                {text}
              </Typography>
              <div className={styles.arrow}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.1 24"
                  height='100%'
                  width="100%">
                  <defs><style>{'.cls-2{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:3px}'}</style></defs>
                  <path className='cls-2' d="M21.1 1.1L32 12 21.1 23M0 12h32"/>
                </svg>
              </div>
            </div>
          </Link>
        }
      </Grid>
    ))}
  </Grid>
)